import React, {Component, Fragment } from 'react';
import { useEffect, useRef } from "react";
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import * as AWSSDK from 'aws-sdk';
import * as Quicksight from 'aws-sdk/clients/quicksight';
import config from '../../config/config';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
var apigClientFactory = require('aws-api-gateway-client').default;
let LanguajeQuickSight = config.LanguajeQuickSight;
let urlAppjs = config.UrlServer;

export class AutomatizacionQuicksight extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.requestApiAmazon = this.requestApiAmazon.bind(this);
        this.state = { 
            isOnline: null,
            defaultConnection: "",
            newConnection: "",
            prefix: ""
        };
        this.errores = "";
        this.embedUrl = "";
        this.defaultData = ""
        this.dashboardRef = React.createRef();
      }

    setConnectionDefault = (event) => {
        this.setState({defaultConnection: event.target.value});
    }

    setConnectionNew = (event) => {
        this.setState({newConnection: event.target.value});
    }

    setPrefix = (event) => {
        this.setState({prefix: event.target.value});
    }

    async requestApiAmazon() {
        alert("Duplicando información en quicksight");
        let AWS = AWSSDK;
        let EmbedUrl = "";
        let Errores = "";
        let apigClient = "";
        let defaultData = "";
        var pathTemplate = '/automationquicksight'
        var method = 'POST';

        await fetch(urlAppjs + '/reporte_quicksights?environment=Default', {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then((success) => {
            if(success["hydra:member"].length >= 1){
                defaultData = success["hydra:member"];
            }
        })

        var body = {
            accountId: "305658496569", 
            dataSourceNew: this.state.newConnection,
            dataSourceDefault:  this.state.defaultConnection,
            prefijo:  this.state.prefix,
            listIds: defaultData
        };
        var pathParams = {};
        var additionalParams = {
        };

        AWS.config.region = 'us-east-1'; // Región
        AWS.config.credentials = await new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-east-1:f446234b-5221-4128-bdcf-972fb559ca3f',
        });

        let awsCredentials = {
            region: "us-east-1",
            accessKeyId: "AKIAUOKVKQY4YPMWTBP7",
            secretAccessKey: "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"
        };
        AWS.config.update(awsCredentials);

        let sts = new AWS.STS();
            
        await sts.assumeRole({
            RoleArn: "arn:aws:iam::305658496569:role/RolQuickSightCognito",
            RoleSessionName: "rafael.chaparroTest"
            }, function(err, data) {
            if (err) {
                Errores = "No se tiene respuesta al assumir el usuario";
            } else {
                AWS.config.update({
                    accessKeyId: data.Credentials.AccessKeyId,
                    secretAccessKey: data.Credentials.SecretAccessKey,
                    sessionToken: data.Credentials.SessionToken
                });

                apigClient = apigClientFactory.newClient({
                    invokeUrl:'https://y0c76baa5j.execute-api.us-east-1.amazonaws.com/Dev',
                    region: 'us-east-1',
                    accessKey: data.Credentials.AccessKeyId,
                    secretKey: data.Credentials.SecretAccessKey,
                    sessionToken: data.Credentials.SessionToken
                });
                
            }
        });

        apigClient = apigClientFactory.newClient({
            invokeUrl:'https://y0c76baa5j.execute-api.us-east-1.amazonaws.com/Dev',
            region: 'us-east-1'
        });

        await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body )
        .then(function(result){
            EmbedUrl = result.data;
            alert("Datos duplicados");
        }).catch( function(result){
            Errores = "No se tiene respuesta al obtener la URL del Dashboard";
        });
    }

    render(){
        return (
            <Fragment>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        <TextField
                            required
                            id="defaultDatabase"
                            label="Database default connection"
                            onChange={this.setConnectionDefault}
                            fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            id="newDatabase"
                            label="Database new connection"
                            onChange={this.setConnectionNew}
                            fullWidth/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            id="prefix"
                            label="Prefix"
                            onChange={this.setPrefix}
                            fullWidth/>
                    </Grid>
                    {this.state.defaultConnection != "" && this.state.newConnection != "" && this.state.prefix != "" && 
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" id="btnConfirmar" style={{ backgroundColor: '#3f51b5' }} onClick={this.requestApiAmazon} >
                                Confirmar
                            </Button>
                        </Grid>
                    }
                    
                </Grid>
            </Fragment>
        );
    }
}

export default AutomatizacionQuicksight;
